/**
* Accordion
* -----------------
* Toggle accordion
*/

module.exports = (function() {

	var $ = require('jquery');
	var Snap = require('snapsvg');

	function Accordion(opts) {
		this.$accordion = $(opts.selector);

		if (!this.$accordion.length) {
			return false;
		}

		var _this = this;

		this.$accordion.on('click', function(e) {
			// select the lines with Snap
			var $svg = $(this).find('.accordion__toggle-btn-svg');
			var plusBtn = Snap($svg[0]);
			var lineOne = plusBtn.select('.accordion__toggle-btn-line-one');
			var lineTwo = plusBtn.select('.accordion__toggle-btn-line-two');
			var $toggleArea = $(this).find('[data-accordion-toggle]');
			var $accordionBody = $(this).find('[data-accordion-body]');
			if (!$(this).hasClass('is-toggled')) {
				// Close all open accordions before opening
				//_this.closeAll();

				lineOne.animate({
					'y1': '50',
					'y2': '50'
				}, 300);

				// Animate plus icon
				$svg.css({
					transform: 'rotate(180deg)'
				});

				// Open accordion
				$accordionBody.slideDown('fast');

				// Set accordion state to 'toggled'
				$(this).addClass('is-toggled');
			} else {
				// only close accordion if user clicks on toggle area
				if ($(e.target).parents('[data-accordion-toggle]').length || $(e.target).is('[data-accordion-toggle]')) {
					lineOne.animate({
						'y1': '5',
						'y2': '95'
					}, 300);

					// Animate plus icon
					$svg.css({
						transform: 'rotate(0deg)'
					});

					// Open accordion
					$accordionBody.slideUp('fast');

					$(this).removeClass('is-toggled');
				}
			}

			return false;
		});
	}

	return Accordion;

})();
