/**
* Partners Nav
* ------------
* On hover show partners links in meta nav
*/

module.exports = (function() {

	var $ = require('jquery');

	function MetaNav() {

		this.$metaNav = $('[data-header-meta]');
		this.$partnersParent = $('[data-partners="parent"]');
		this.$partnersChild = $('[data-partners="child"]');
		this.partnersParentIndex = this.$partnersParent.index();

		this.$metaNav.on('mouseover', function(e) {
			var $target = $(e.target),
				targetIndex = $target.parents('li').first().index();

			if (targetIndex > this.partnersParentIndex) {
				return;
			}

			this.$partnersChild.removeClass('meta-nav__item--hidden').addClass('meta-nav__item');
		}.bind(this));

		this.$metaNav.on('mouseout', function(e) {
			var $target = $(e.currentTarget),
				targetIndex = $target.parents('li').first().index();

			if (targetIndex > this.partnersParentIndex) {
				return;
			}

			this.$partnersChild.addClass('meta-nav__item--hidden').removeClass('meta-nav__item');
		}.bind(this));
	}

	return MetaNav;

})();
