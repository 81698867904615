/**
* Table
* -----------------
* Show and hide shadow depending on whether table is scrollable
*/

module.exports = (function() {

	var $ = require('jquery');

	function Table(opts) {
		this.$table = $(opts.selector);

		if (!this.$table.length) {
			return false;
		}

		this.$shadow = this.$table.find('[data-table-shadow]');
		this.$container = this.$table.find('[data-table-container]');
		this.init.bind(this)();

		$(window).resize(function() {
			this.resize.bind(this)();
		}.bind(this));
	}

	Table.prototype.init = function() {
		if (this.hasHorizontalScrollbar(this.$container)) {
			this.$shadow.show();
		} else {
			this.$shadow.hide();
		}
	};

	Table.prototype.resize = function() {
		this.init.bind(this)();
	};

	Table.prototype.hasHorizontalScrollbar = function(el) {
		return el.get(0).scrollWidth > el.get(0).clientWidth;
	};

	return Table;

})();
