/**
* Case Study Slider
* -----------------
* Set up slick slider with arrow icon for next pagination
*/

module.exports = (function() {

	require('slick-carousel');

	var $ = require('jquery');

	function CaseStudySlider(opts) {
		this.$slider = $(opts.selector);

		if (!this.$slider.length) {
			return false;
		}

		this.$slider.on('init', this.appendArrow.bind(this));
		this.$slider.on('beforeChange', this.customActiveDotClass.bind(this));
		this.customPaginationClass.bind(this)();

		this.$slider.slick({
			mobileFirst: true,
			dots: true,
			infinite: true,
			arrows: false,
			fade: true,
			cssEase: 'linear',

			customPaging: function(slick, index) {
				return '<span class="pagination__dot"></span>';
			}
		});
	}

	CaseStudySlider.prototype.customPaginationClass = function() {
		setTimeout(function() {
			this.$slider.find('.slick-dots').addClass('pagination');
			this.$slider.find('.slick-dots').children('li').eq(0).addClass('is-active');
			this.$slider.find('.slick-dots').children('li').addClass('pagination__dot-container');
		}.bind(this), 0);
	};

	CaseStudySlider.prototype.customActiveDotClass = function(event, slick, currentSlide, nextSlide) {
		this.$slider.find('.slick-dots').children('li').removeClass('is-active').eq(nextSlide).addClass('is-active');
	};

	CaseStudySlider.prototype.arrowInit = function() {
		this.$slider.find('.slick-arrow').on('click', function() {
			this.$slider.slick('slickNext');
		}.bind(this));
	};

	CaseStudySlider.prototype.appendArrow = function() {
		this.$slider.find('.slick-dots').append('<li class="slick-arrow"><span class="icon-arrow"></span></li>');
		this.arrowInit();
	};

	CaseStudySlider.prototype.arrowInit = function() {
		this.$slider.find('.slick-arrow').on('click', function() {
			this.$slider.slick('slickNext');
		}.bind(this));
	};

	return CaseStudySlider;

})();
