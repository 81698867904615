(function() {

	'use strict';

	global.jQuery = global.$ = require('jquery');
	require('../../../bower_components/toggles-switches/toggles-switches');

	// require components
	var LanguageSelector = require('../components/language-selector'),
		Picker = require('../components/picker'),
		HeaderScroller = require('../components/header-scroller'),
		HeightMatcher = require('../components/height-matcher'),
		MarketoForm = require('../components/marketo-form'),
		CaseStudySlider = require('../components/case-study-slider'),
		HistorySlider = require('../components/history-slider'),
		LogoWallSlider = require('../components/logo-wall-slider'),
		Counter = require('../components/counter'),
		Parallax = require('../components/parallax'),
		WayPoints = require('../components/waypoints'),
		video = require('../components/video'),
		attachFastClick = require('fastclick'),
		search = require('../components/search'),
		Table = require('../components/table'),
		TableDrawer = require('../components/table-drawer'),
		Accordion = require('../components/accordion'),
		PartnersNav = require('../components/partners-nav');

	// document ready
	$(function() {

		//Ensure javascript re-runs on mobile when hitting back button
		window.onunload = function() {};

		var $parallax = $('[data-parallax]');

		if ($parallax.length) {
			var parallax = new Parallax({
				breakpoint: '1100px'
			});
		}

		// initialize language selector
		var langSelector = new LanguageSelector({
			btn: 'data-lang-btn',
			dropdown: 'data-lang-selector'
		});

		// initialize header scroller
		var headerScroller = new HeaderScroller({
			selector: '[data-header]:not(.header--static)',
			gradientSelector: '[data-header-scroll]',
			fixedOffsetSelector: '[data-header-meta]',
			gradientClass: 'header__gradient--active',
			fixedClass: 'header--fixed'
		});

		// percentage counter
		var attr = 'data-counter',
			dataAttr = '[' + attr + ']',
			$counters = $(dataAttr),
			counters = [];

		$counters.each(function() {
			counters.push(new Counter({
				selector: dataAttr,
				to: $(this).attr(attr)
			}));
		});

		// home page logo wall slider
		var logoWallSlider = new LogoWallSlider({
			selector: '[data-logo-wall-slider]'
		});

		// home page case study slider
		var caseStudySlider = new CaseStudySlider({
			selector: '[data-case-study-slider]'
		});

		// about page history slider
		var historySlider = new HistorySlider({
			sliderSelector: '[data-history-slider]',
			slidesSelector: '[data-history-slide]',
			titlesSelector: '[data-history-title]',
			navClass: 'history-timeline__list'
		});

		// table component
		var table = new Table({
			selector: '[data-table]'
		});

		// accordion component
		var accordion = new Accordion({
			selector: '[data-accordion]'
		});

		// initalize pickers
		var pickers = [];
		$('[data-picker]').each(function() {
			pickers.push(new Picker($(this)));
		});

		// initialize way point fade ins
		var waypoints = new WayPoints();

		var partnersNav = new PartnersNav();

		// remove 300ms delay from clicks / taps
		attachFastClick(document.body);
	});

	$(window).load(function() {
		// initialize height matcher
		var heightMatcherNews = new HeightMatcher({
			selector: '[data-news]'
		});

		var eventsMatcherNews = new HeightMatcher({
			selector: '[data-events]'
		});

		var heightMatcherAwards = new HeightMatcher({
			selector: '[data-awards-large]'
		});

		var calloutsMatcher = new HeightMatcher({
			selector: '[data-callout]'
		});

		var contentCardMatcher = new HeightMatcher({
			selector: '[data-content-card]'
		});

		// initialize marketo forms
		var marketoForm = new MarketoForm();
		marketoForm.renderNewsletter();
		marketoForm.renderDemoRequest();
	});
})();
