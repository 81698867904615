/**
* Height Matcher
* --------------
* Set min height of a selector to equal that of the largest
*/

module.exports = (function() {

	var $ = require('jquery');

	function HistorySlider(opts) {
		if (!opts.sliderSelector || !opts.slidesSelector || !opts.titlesSelector || !opts.navClass) {
			return false;
		}

		this.opts = opts;
		this.$historySlider = $(this.opts.sliderSelector);
		this.$historySlides = this.$historySlider.find(this.opts.slidesSelector);
		this.$historySlideTitles = this.$historySlides.find(this.opts.titlesSelector);

		this.$historySlideTitles.on('click', function(e) {
			var index = $(this).parents(opts.slidesSelector).index();
			$(opts.sliderSelector).slick('slickGoTo', index);
		});

		this.init();
	}

	HistorySlider.prototype.init = function() {
		$('[data-history-slider]').slick({
			mobileFirst: true,
			dots: true,
			infinite: false,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true,
			centerMode: true,
			centerPadding: '20px',
			speed: 200,
			dotsClass: this.opts.navClass,
			customPaging: function(slick, index) {
				var year = this.$historySlides.eq(index).find(this.opts.titlesSelector).html();
				return '<a class="history-timeline__link" href="#">' + year + '</a>';
			}.bind(this),

			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				}
			]
		});
	};

	return HistorySlider;

})();
