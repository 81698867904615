/**
* Header Scroll
* -------------
* Add active class to header gradient when user scrolls to fade in gradient
* Remove active class when user scrolls back to top
*/

module.exports = (function() {

	var $ = require('jquery'),
		_ = {
			throttle: require('lodash/function/throttle')
		};

	function HeaderScroll(opts) {
		if (!opts.selector || !opts.gradientSelector || !opts.gradientClass) {
			return false;
		}

		this.$header = $(opts.selector);
		this.$gradient = $(opts.gradientSelector);
		this.gradientClass = opts.gradientClass;
		this.gradientOffset = opts.gradientOffset || 10;

		// header element found and active class provided
		if (!this.$header.length || !this.$gradient.length || !this.gradientClass) {
			return false;
		}

		// if offset selector and fixed class are optionally specified
		if (opts.fixedOffsetSelector && opts.fixedClass) {
			this.$fixedOffset = $(opts.fixedOffsetSelector);
			this.fixedClass = opts.fixedClass;

			// if specififed then the element must exist
			if (!this.$fixedOffset.length) {
				return false;
			}

			// store it's bottom position value
			this.offset = this.$fixedOffset.height();
		}

		// throttle scroll handler to 20ms
		$(window).on('scroll', _.throttle(this.handleScroll.bind(this), 20));
	}

	// if user scrolls more than 10px then add active class
	HeaderScroll.prototype.handleScroll = function(e) {
		var offset = $(window).scrollTop();

		// always remove gradient / fixed class on small screens
		if (window.matchMedia('(max-width: 68.750em)').matches) {
			this.$gradient.removeClass(this.gradientClass);
			this.$header.removeClass(this.fixedClass);
			return;
		}

		// if offset specified and we've scrolled down and past
		if (this.offset && offset >= this.offset) {
			this.$header.addClass(this.fixedClass);

		// if offset is specified and we've scrolled up and past
		} else if (this.offset && offset < this.offset) {
			this.$header.removeClass(this.fixedClass);
		}

		// 10px offset for adding gradient
		if (offset > this.gradientOffset) {
			this.$gradient.addClass(this.gradientClass);
		} else {
			this.$gradient.removeClass(this.gradientClass);
		}
	};

	return HeaderScroll;

})();
