(function() {

	'use strict';

	var $headerIcon = $('.header__search'),
	$headerNav = $('.header__nav'),
	$headerSearch = $('.header__search-box'),
	$searchInput = $('.header__search-box-input'),
	$searchClose = $('.header__search-close'),
	$handheldMenuIcon = $('.menu-icon');

	$headerIcon.click(function(e) {
		e.preventDefault();
		$headerNav.addClass('header__nav--inactive');
		$headerSearch.addClass('header__search-box--active');
		setTimeout(function() {
			document.getElementsByClassName('header__search-box-input')[0].focus();
		}, 550);
	});

	$searchClose.click(function(e) {
		e.preventDefault();
		$headerSearch.removeClass('header__search-box--active');
		setTimeout(function() {
			$headerNav.removeClass('header__nav--inactive');
		}, 100);
	});

})();
