/**
* Height Matcher
* --------------
* Set min height of a selector to equal that of the largest
*/

module.exports = (function() {

	require('../../../bower_components/waypoints/lib/jquery.waypoints');

	var $ = require('jquery');

	function init() {

		var $header = $('[data-header]'),
			$mastheadInner = $('[data-masthead-inner]');

		if ($header.length) {
			setTimeout(function() {
				$header.addClass('header--active');
			}, 1000);
		}

		if ($mastheadInner.length) {
			setTimeout(function() {
				$mastheadInner.addClass('masthead__inner--active');
			}, 600);
		}

		setTimeout(function() {
			var $logosPanel = $('[data-social-proof]'),
				$logos = $('[data-client-logo]');

			if ($logosPanel.length) {
				var logos1 = new Waypoint({
					element: $logosPanel.get(0),
					handler: function() {
						$logos.each(function(i) {
							setTimeout(function() {
								$(this).addClass('social-proof__list-item--active');
							}.bind(this), 150 * i);
						});
					},

					offset: '85%'
				});
			}

			var $featuresPanel = $('[data-features]'),
				$features = $('[data-feature]');

			if ($featuresPanel.length) {
				var features1 = new Waypoint({
					element: $featuresPanel.get(0),
					handler: function() {
						$features.each(function(i) {
							setTimeout(function() {
								$(this).addClass('feature--active');
							}.bind(this), 250 * i);
						});
					},

					offset: '75%'
				});
			}

			var $awardsPanels = $('[data-awards]'),
				$awards = $('[data-award]');

			if ($awardsPanels.length) {
				var awards1 = new Waypoint({
					element: $awardsPanels.get(0),
					handler: function() {
						$awards.each(function(i) {
							setTimeout(function() {
								$(this).addClass('awards__item--active');
							}.bind(this), 150 * i);
						});
					},

					offset: '75%'
				});
			}
		}, 1200);
	}

	return init;

})();
