/**
* Table Drawer
* -----------------
* Slide a div out of a table when an icon is clicked
*/

(function() {

	'use strict';

	var $tableDrawerIcon = $('[data-table-drawer-icon]'),
	$tableDrawer = $('[data-table-drawer]'),
	$tableCloseIcon = $('[data-table-close]'),
	$tableDrawerAnimationLength = 400;

	$tableDrawerIcon.each(function($i) {
		$(this).on('click', function($el) {

			// Sorry about the following animation, but CSS doesn't react well to height:auto animations.
			if ($tableDrawer.eq($i).css('height') == '0px') {
				$tableDrawerIcon.eq($i).addClass('table__info--active');
				$tableDrawer.eq($i).animate({ height: $tableDrawer.eq($i).get(0).scrollHeight }, $tableDrawerAnimationLength, function() {
						$tableDrawer.eq($i).css('height', 'auto');
					});
			} else {
				$tableDrawer.eq($i).animate({ height: 0 }, $tableDrawerAnimationLength, function() {
					$tableDrawerIcon.eq($i).removeClass('table__info--active');
				});
			}
		});
	});

	$tableCloseIcon.each(function($i) {
		$(this).on('click', function($el) {
			$tableDrawer.eq($i).animate({ height: 0 }, $tableDrawerAnimationLength, function() {
				$tableDrawerIcon.eq($i).removeClass('table__info--active');
			});
		});
	});

})();
