/**
* Marketo Form
* ------------
*
* Load Marketo forms and override styles to fit design
* Style overriding handled in CSS where possible
*/

module.exports = (function() {

	var $ = require('jquery');

	function MarketoForm() {

	}

	MarketoForm.prototype.renderNewsletter = function() {
		var $success = $('[data-newsletter-success]');

		// Newsletter Marketo form
		MktoForms2.loadForm('//app-sj04.marketo.com', '376-EUE-844', 2586, function(form) {
			// set form placeholder
			$footerNewsletter = $('.footer__newsletter-form .mktoField');
			$footerNewsletter.attr({
				'placeholder': 'Enter your email address...'
			});

			$footerNewsletter.addClass('field--secondary');

			// remove default btn class and apply custom
			// move element next to input to get around float issue
			$('.footer__newsletter-form .mktoButton').removeClass('mktoButton')
							.addClass('btn--blue-dark')
							.html('Join Newsletter')
							.insertAfter('.footer__newsletter-form .mktoEmailField');

			// on success prevent reload and show success message
			form.onSuccess(function(values, followUpUrl) {
				// hide form
				form.getFormElem().hide();

				// show success message
				$success.show();

				// return false to prevent the submission handler from taking the lead to the follow up url
				return false;
			});
		});
	};

	MarketoForm.prototype.renderDemoRequest = function() {
		var $success = $('[data-demo-success]'),
			$demoRequest = $('.demo-request__form');

		MktoForms2.loadForm('//app-sj04.marketo.com', '376-EUE-844', 2585, function(form) {

			var $stateRow = $demoRequest.find('.mktoPlaceholderState').parent('.mktoFormRow');

			$stateRow.hide();
			$demoRequest.find('.mktoButton').addClass('btn-fill');
			$demoRequest.find('input').addClass('field');
			$demoRequest.find('[name=SrcID]').parent('.mktoFormRow').hide();

			$demoRequest.find('[name=Country]').on('change', function() {
				if ($stateRow.find('[name=State]').length) {
					$stateRow.show();
				} else {
					$stateRow.hide();
				}
			});

			form.onSuccess(function(values, followUpUrl) {
				$demoRequest.hide();

				$(window).scrollTop(0);

				// show success message
				$success.fadeIn(300);

				// return false to prevent the submission handler from taking the lead to the follow up url
				return false;
			});
		});
	};

	return MarketoForm;
})();
