(function() {

	'use strict';
	var $videoEmbed = $('[data-video-embed]');
	var $body = $('body');
	var src = null;
	var tmpl = null;
	var templates = require('../../../dist/js/templates');

	$videoEmbed.on('click', function() {
		src = $(this).data('video-embed');
		tmpl = templates.embed({
			src: src
		});

		$(tmpl).prependTo($body).fadeTo('fast', 1);
		$('<div class="overlay"></div>').prependTo($body).fadeTo('fast', 1);

		return false;
	});

	// Remove if you click anywhere outside the video
	$(document).on('click', '.overlay', function() {
		$('[data-video-embed-iframe]').fadeTo('fast', 0, function() {
			$(this).remove();
		});

		$('.overlay').fadeTo('fast', 0, function() {
			$(this).remove();
		});
	});

	// Remove if you click the close button
	$(document).on('click', '[data-video-embed-close-btn]', function() {
		$('[data-video-embed-iframe]').fadeTo('fast', 0, function() {
			$(this).remove();
		});

		$('.overlay').fadeTo('fast', 0, function() {
			$(this).remove();
		});
	});

})();
