/**
* Language selector
* -----------------
* Close language selector when user clicks outside of component
* Triggering handled via toggles and switches
*/

module.exports = (function() {

	var $ = require('jquery');

	function LanguageSelector(opts) {
		if (!opts.btn || !opts.dropdown) {
			return false;
		}

		var langAttrs = {
			btn: opts.btn,
			btnSel: '[' + opts.btn + ']',
			selector: opts.dropdown,
			selectorSel: '[' + opts.dropdown + ']'
		};

		// close language selector when user clicks anywhere outside of element
		$(document).on('click', function(e) {
			var $target = $(e.target);

			// click outside selector or button
			if (!$target.attr(langAttrs.selector) &&
				!$target.attr(langAttrs.btn) &&
				!$target.parents(langAttrs.selectorSel).length &&
				!$target.parents(langAttrs.btnSel).length) {

				// remove active class added by toggles & switches
				$(langAttrs.btnSel + ',' + langAttrs.selectorSel).removeClass('active');
			}
		});
	}

	return LanguageSelector;

})();
