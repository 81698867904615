/**
* Case Study Slider
* -----------------
* Set up slick slider with arrow icon for next pagination
*/

module.exports = (function() {

	require('slick-carousel');

	var $ = require('jquery');

	function LogoWallSlider(opts) {
		this.$slider = $(opts.selector);

		if (!this.$slider.length) {
			return false;
		}

		this.$slider.slick({
			mobileFirst: true,
			dots: false,
			infinite: true,
			arrows: true,
			fade: false,
			appendArrows: '.social-proof',
			prevArrow: '<span class="social-proof__list-arrow--prev icon-arrow"></span>',
			nextArrow: '<span class="social-proof__list-arrow--next icon-arrow"></span>',
			cssEase: 'linear',
			speed: 200,
			slidesToShow: 1,
			slidesToScroll: 1,

			responsive: [
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5
					}
				},

				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},

				{
					breakpoint: 900,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},

				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});
	}

	return LogoWallSlider;

})();
