/**
* Height Matcher
* --------------
* Set min height of a selector to equal that of the largest
*/

module.exports = (function() {

	var $ = require('jquery');

	require('../../../bower_components/waypoints/lib/jquery.waypoints');

	function Counter(opts) {
		this.elem = document.querySelector(opts.selector);
		this.to = opts.to || 100;
		this.breakpoint = opts.breakpoint || '1015px';
		this.interval = null;

		if (!this.elem) {
			return false;
		}

		if (!window.matchMedia('(min-width: ' + this.breakpoint + ')').matches) {
			this.elem.innerHTML = this.numberWithCommas(this.to);
			return false;
		}

		var counterWaypoint = new Waypoint({
			element: this.elem,
			handler: this.handleWaypoint.bind(this),
			offset: '90%'
		});

		var counterClearWaypoint = new Waypoint({
			element: this.elem,
			handler: this.clear.bind(this),
			offset: '100%'
		});
	}

	Counter.prototype.handleWaypoint = function() {
		if (this.elem.innerHTML !== '0') {
			return false;
		}

		var i = 0;

		this.interval = setInterval(function() {
			this.elem.innerHTML = this.numberWithCommas(i);

			if (i >= this.to) {
				clearInterval(this.interval);
			} else {
				i = i + 25;
			}
		}.bind(this), 1);
	};

	Counter.prototype.clear = function() {
		this.elem.innerHTML = '0';
	};

	Counter.prototype.numberWithCommas = function(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	return Counter;

})();
