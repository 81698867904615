/**
* Height Matcher
* --------------
* Set min height of a selector to equal that of the largest
*/

module.exports = (function() {

	var $ = require('jquery'),
		largestHeight = 0;

	function HeightMatcher(opts) {
		this.$selector = $(opts.selector);

		if (!this.$selector.length) {
			return false;
		}

		window.addEventListener('resize', this.setHeightsIfViewportLarge.bind(this));
		this.setHeightsIfViewportLarge();
	}

	// if viewport > 860px set heights to largest in group else remove inline height
	HeightMatcher.prototype.setHeightsIfViewportLarge = function() {
		if (window.matchMedia('(min-width: 860px)').matches) {
			this.setHeights();
		} else {
			this.removeheights();
		}
	};

	// loop through each and set height to largest
	HeightMatcher.prototype.setHeights = function() {
		this.removeheights();
		this.$selector.delay(200).each(function() {
			var height = $(this).outerHeight();

			if (height > largestHeight) {
				largestHeight = height;
			}
		});

		this.$selector.css('height', largestHeight);
	};

	// remove inline height style
	HeightMatcher.prototype.removeheights = function() {
		largestHeight = 0;
		this.$selector.css('height', '');
	};

	return HeightMatcher;

})();
